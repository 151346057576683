<template>
  <div class="layout">
    <Header />
    <div class="content">
      <router-view :key="key"></router-view>
    </div>
     
  </div>
</template>

<script>
import Header from "./components/header.vue";

export default {
  name: "layout",
  components: {
    Header,
  },
  data() {
    return {
    };
  },
  created(){
console.log(this.$route.path)
  },
  computed: {
    key() {
        console.log(this.$route.path)
      return this.$route.path;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.layout {
  width: 100%;
  height: 100%;
  .content{
    width: 100%;
    height: calc(100vh - 60px);
  }
}
</style>
