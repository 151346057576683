<template>
  <div class="header">
    <div class="header-title">
      <div class="header-title-box">
        <div
          :class="item.icon ? 'header-title-item2' : 'header-title-item'"
          v-for="(item, index) in menu"
          :style="
            item.active
              ? 'border-bottom: 2px solid #13808a;color:#13808a'
              : 'border-bottom: 2px solid #fff;color:#000'
          "
          :key="index"
          @click="selectMenu(item)"
        >
          {{ item.title }}
          <img :src="item.icon" v-if="!item.title" alt="" class="logo-img" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      menu: [
        {
          title: "走进衣通",
          path: "/intoXiyitong",
          active: false,
        },
        {
          title: "衣通品牌",
          path: "/brand",
          active: false,
        },
        {
          title: "衣通门店",
          path: "/store",
          active: false,
        },
        {
          title: "",
          icon: require("@/assets/logo.png"),
          path: "/index",
          active: false,
        },

        {
          title: "衣通工厂",
          path: "/factory",
          active: false,
        },
        {
          title: "衣通APP",
          path: "/xiyitongApp",
          active: false,
        },
        {
          title: "合作伙伴",
          path: "/partner",
          active: false,
        },
      ],
    };
  },
  created() {
    this.menu = this.menu.map((item) => {
      item.active = false;
      if (item.path == this.$route.path && item.title != "") {
        item.active = true;
      }
      return item;
    });
  },
  watch: {
    $route(data) {
      this.menu = this.menu.map((item) => {
        item.active = false;
        if (item.path == data.path && item.title != "") {
          item.active = true;
        }
        return item;
      });
    },
  },
  methods: {
    selectMenu(data) {
      if (!data.icon) {
        this.menu = this.menu.map((item) => {
          item.active = false;
          if (item.title == data.title) {
            item.active = true;
          }
          return item;
        });
      } else {
        this.menu = this.menu.map((item) => {
          item.active = false;
          return item;
        });
      }
      if (data.path != "" && this.$route.path != data.path) {
        this.$router.push({ path: data.path });
      }

      console.log(data);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  .header-title {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .header-title-box {
      display: flex;
      .header-title-item {
        margin: 0 40px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        font-size: 18px;
      }
      .header-title-item2 {
        width: 200px;
        height: 60px;
        line-height: 60px;
        justify-content: center;
        display: flex;
        align-items: center;
        .logo-img {
          height: 50px;
        }
      }
      .acitve-line {
      }
    }
  }
}
</style>
