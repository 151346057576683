import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/views/layout";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Layout",
    component: Layout,
    redirect: "/index",
    children: [
      {
        path: "/index",
        name: "index",
        component: () => import("@/views/index/index.vue"),
        meta: {
          title: "首页"
        }
      },
      {
        path: "/intoXiyitong",
        name: "intoXiyitong",
        component: () => import("@/views/intoXiyitong/index.vue"),
        meta: {
          title: "走进衣通"
        }
      },
      {
        path: "/brand",
        name: "brand",
        component: () => import("@/views/brand/index.vue"),
        meta: {
          title: "衣通品牌"
        }
      },
      {
        path: "/factory",
        name: "factory",
        component: () => import("@/views/factory/index.vue"),
        meta: {
          title: "衣通工厂"
        }
      },
      {
        path: "/xiyitongApp",
        name: "xiyitongApp",
        component: () => import("@/views/xiyitongApp/index.vue"),
        meta: {
          title: "衣通APP"
        }
      },
      {
        path: "/partner",
        name: "partner",
        component: () => import("@/views/partner/index.vue"),
        meta: {
          title: "合作伙伴"
        }
      },
      {
        path: "/openStore",
        name: "openStore",
        component: () => import("@/views/partner/components/openStore/index.vue"),
        meta: {
          title: "我要开店"
        }
      },
      {
        path: "/cooperation",
        name: "cooperation",
        component: () => import("@/views/partner/components/cooperation/index.vue"),
        meta: {
          title: "我要合作"
        }
      },
      {
        path: "/receipt",
        name: "receipt",
        component: () => import("@/views/partner/components/receipt/index.vue"),
        meta: {
          title: "我有团单"
        }
      },
      {
        path: "/store",
        name: "store",
        component: () => import("@/views/store/index.vue"),
        meta: {
          title: "衣通门店"
        }
      }
    ]
  }
];

const router = new VueRouter({
  publicPath: "/",
  mode: "history",
  routes
});

export default router;
